import * as React from "react";

// https://react-svgr.com/playground/
const SvgComponent = (props: any) => (
  <svg
    className={props.className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 13.2229L8.45 16.6999C8.54293 16.8387 8.66768 16.9533 8.81385 17.0342C8.96002 17.1151 9.12339 17.1599 9.29037 17.1648C9.45735 17.1698 9.6231 17.1349 9.77384 17.0629C9.92458 16.9909 10.0559 16.8839 10.157 16.7509L18 6.82788"
      stroke="#886C4E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
