import { HorsedayIconProps } from "./IconProps";

const InfoIcon = (props: HorsedayIconProps) => {
  const width = props.width ?? 24;
  const height = props.height ?? 24;
  const stroke = props.stroke ?? "black";
  return <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_8453_57771)">
      <path d="M12 23.001C18.0751 23.001 23 18.0761 23 12.001C23 5.92584 18.0751 1.00098 12 1.00098C5.92487 1.00098 1 5.92584 1 12.001C1 18.0761 5.92487 23.001 12 23.001Z" stroke={stroke} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.5 17.005H13C12.7348 17.005 12.4804 16.8996 12.2929 16.7121C12.1054 16.5246 12 16.2702 12 16.005V9.505C12 9.3724 11.9473 9.24522 11.8536 9.15145C11.7598 9.05768 11.6326 9.005 11.5 9.005H10" stroke={stroke} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11.7412 7C11.6031 7 11.4912 6.88807 11.4912 6.75C11.4912 6.61193 11.6031 6.5 11.7412 6.5" stroke={stroke} />
      <path d="M11.7412 7C11.8792 7 11.9912 6.88807 11.9912 6.75C11.9912 6.61193 11.8792 6.5 11.7412 6.5" stroke={stroke} />
    </g>
    <defs>
      <clipPath id="clip0_8453_57771">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>

}
export default InfoIcon;
