import { useEffect, useState } from "react";
import { clsx } from "clsx";
import { devPrint } from "@/utils/devUtils";

interface IModal {
  id: string;
  show: boolean;
  onClose: () => void;
  title: string;
  body: string;
}

const Modal = ({ title, body, ...props }: IModal) => {
  const [pos, setPos] = useState([] as number[]);

  useEffect(() => {
    if (!props.show) {
      return;
    }
    let hasrun = false;
    devPrint("Show?");
    const handleClose = (e: MouseEvent) => {
      var x = Math.min(e.clientX, window.innerWidth - 400);
      setPos([x, e.clientY]);
      if (!hasrun) {
        hasrun = true;
        return;
      }

      props.onClose();
    };

    document.addEventListener("click", handleClose);
    return () => document.removeEventListener("click", handleClose);
  }, [props.show]);

  const renderClose = () => {
    return (
      <button
                onClick={() => props.onClose()}
                type="button"
                className="absolute right-1 top-1 text-gray-400 bg-transparent hover:bg-gray-200 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
                data-modal-hide={props.id}
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
    )
  }

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 ${props.show ? 'flex' : 'hidden'} items-center justify-center z-50`}
      onClick={props.onClose} // Click outside to close
    >
      <div
        id={props.id}
        onClick={(e) => e.stopPropagation()} // Prevent clicks from closing the modal
        className="p-8"
      >

        <div className="relative bg-white rounded-lg shadow py-2 sm:py-3 px-3 sm:px-4">
          {renderClose()}
          <div className="flex items-center justify-between border-b rounded-t pb-2 ">
            <h3 className="font-medium">{title}</h3>
          </div>
          <div className="mt-2">
            <p className="text-sm leading-relaxed font-light">
              {body}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
