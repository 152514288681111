import { clsx } from "clsx";

interface ITextContainer {
  children: string | string[] | JSX.Element;
  color?: string;
  className?: string;
}

const TextContainer = ({ children, ...props }: ITextContainer) => {
  return (
    <span
      className={clsx(
        props.className,
        props.color ?? "bg-primary-surface text-primary",
        "p-1 rounded-md font-semibold"
      )}
    >
      {children}
    </span>
  );
};

export default TextContainer;
