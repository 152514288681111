import {
  DurationEnum,
  EntitlementEnum,
  RevenuecatSubscriptions,
  StripePrice,
  StripePricesAndProducts,
  StripeProduct,
} from "@/openapi";
import { devPrint, isLocalhost, isTestEnv } from "./devUtils";
import { _ } from "@/pages/_app";

const getHttp = () => {
  return isLocalhost() ? "http://" : "https://";
};

export const getPaymentReturnSuccessUrl = (
  product?: IStripeProductPrice,
  buyAsGiftcard?: boolean
) => {
  var base = getHttp() + window.location.host + "/checkout/confirm/";
  if (!product) {
    return base;
  }
  return (
    base +
    "?product=" +
    product.product.name +
    (buyAsGiftcard ? "&buyAsGiftcard=" + buyAsGiftcard : "")
  );
};

export const getPaymentReturnCancelUrl = (product?: IStripeProductPrice) => {
  const base = getHttp() + window.location.host + "/checkout/cancel/";
  if (!product) {
    return base;
  }
  return base + "?product=" + product.product.name;
};

interface MyStripePrice extends StripePrice {
  monthPrice: number;
  discount?: number;
}

export interface IStripeProductPrice {
  price: MyStripePrice;
  product: StripeProduct;
}

export const getStripePublishableKey = () => {
  if (isTestEnv()) {
    // todo set in secret manager
    return "pk_test_51O5A8OFoSU7D8eAQu3xlfziloCf38tYnTLPFBXvUruguvLYFTdD0CoSaffpdfyHnGH2iIACeXTVA2iYNaQdvovYE00eCdqS1tm";
  }
  return "pk_live_51O5A8OFoSU7D8eAQaJ1FOldW2pimmI2LlqQFtkFz2U6cMJzxcb5bnoleoZaiIIE11SZwucnMtIdpisduxRxMfeOw00MdAcDrLs";
};

export const getActiveProductsFromPricesProducts = (
  pricesProducts: StripePricesAndProducts
) => {
  let products: IStripeProductPrice[] = [];
  let monthProProd: IStripeProductPrice | undefined = undefined;
  let yearProProd: IStripeProductPrice | undefined = undefined;

  let monthBrowseProd: IStripeProductPrice | undefined = undefined;
  let yearBrowseProd: IStripeProductPrice | undefined = undefined;
  for (let item of pricesProducts.products) {
    if (!item.active) {
      continue;
    }
    for (let price of pricesProducts.prices) {
      if (
        item.defaultPrice === price.id ||
        (item.defaultPrice === undefined && price.product === item.id)
      ) {
        let monthPrice = price.unitAmount;
        if (item.metadata.duration === "YEAR") {
          monthPrice = monthPrice / 12;
        }

        let myPrice: MyStripePrice = {
          ...price,
          monthPrice: monthPrice,
        };
        var product: IStripeProductPrice = {
          price: myPrice,
          product: item,
        };
        if (item.metadata.duration == "MONTH") {
          if (item.metadata.entitlement === "PRO") {
            monthProProd = product;
          } else {
            monthBrowseProd = product;
          }
        } else if (item.metadata.duration === "YEAR") {
          if (item.metadata.entitlement === "PRO") {
            yearProProd = product;
          } else {
            yearBrowseProd = product;
          }
        }
        products.push(product);
        break;
      }
    }
  }

  if (monthProProd && yearProProd) {
    yearProProd.price.discount =
      1 - yearProProd.price.monthPrice / monthProProd.price.monthPrice;
  }
  if (monthBrowseProd && yearBrowseProd) {
    yearBrowseProd.price.discount =
      1 - yearBrowseProd.price.monthPrice / monthBrowseProd.price.monthPrice;
  }
  // to find discount

  return products;
};

export const getGiftcardProduct = (
  _product: IStripeProductPrice,
  pricesProducts: StripePricesAndProducts
): { giftcardProduct?: IStripeProductPrice; product?: IStripeProductPrice } => {
  let giftcardProduct: IStripeProductPrice = _product;
  let product: IStripeProductPrice | undefined = undefined;
  let oneTimePrice: MyStripePrice;

  // find which one-time-payment product, _product is refrencing
  // _product will be a subscription equivalent
  for (let item of pricesProducts.prices) {
    if (!item.active) {
      continue;
    }
    if (item.product === _product.price.product && !item.recurring) {
      product = {
        product: _product.product,
        price: { ...item, monthPrice: 0 },
      };
    }
  }

  return { giftcardProduct, product };
};

export const entitlementToPlan = (entitlement: EntitlementEnum) => {
  if (entitlement === "BROWSING") {
    return _("Silver");
  }
  if (entitlement === "PRO") {
    return _("Premium");
  }
  return "";
};

export const durationToReadable = (duration: DurationEnum) => {
  if (duration === "MONTH") {
    return _("Month");
  }
  if (duration === "YEAR") {
    return _("Year");
  }
  return "";
};

export const priceToReadable = (unitAmount: number, currency: string) => {
  let price = (unitAmount / 100).toFixed(2);

  var code = "";
  if (currency === "usd") {
    code = "$";
  } else if (currency === "eur") {
    code = "€";
  }

  if (code !== "") {
    return code + price;
  }
  return price + " " + currency;
};

export const hasActivieSubscription = (subs?: RevenuecatSubscriptions[]) => {
  if (!subs) {
    return false;
  }
  for (var item of subs) {
    if (item.unsubscribeDetectedAt === null) {
      return true;
    }
  }
  return false;
};

export const getProductTitle = (product: IStripeProductPrice) => {
  return product.product.metadata.duration === "MONTH"
    ? _("Month Premium Subscription")
    : _("Year Premium Subscription");
};
