// OptionsCard.tsx
import Button from "@/components/buttons/Button";
import TextContainer from "@/components/containers/TextContainer";

import FeaturesList, {
  FeatureItemComponent,
  getFeatures,
} from "@/components/monitize/FeaturesList";
import {
  EntitlementEnum,
  StripePricesAndProducts,
  User,
} from "@/openapi";
import { trackEvent } from "@/utils/analyticsFunctions";
import {
  IStripeProductPrice,
  getActiveProductsFromPricesProducts,
  priceToReadable,
} from "@/utils/paymentUtils";
import { entitlementToString } from "@/utils/stringUtils";

import { clsx } from "clsx";
import React from 'react';
import { t } from "@/utils/translation";

// translation function
export const _ = t;

interface OptionsCardProps {
  entitlement: EntitlementEnum;
  description: String;
  pricesProducts: StripePricesAndProducts; // Adjust according to actual type
  user: User | undefined; // Adjust the User type according to your context
  setSelectedProduct: (product: IStripeProductPrice | undefined) => void;
}

export const premiumBadge = (className?: string) => {
  return (
    <TextContainer
      color={"bg-premium-background text-premium-text"}
      className={className}
    >
      {entitlementToString("PRO")}
    </TextContainer>
  );
};

export const silverBadge = (className?: string) => {
  return (
    <TextContainer
      color={"bg-browser-background text-dark-brown"}
      className={className}
    >
      {entitlementToString("BROWSING")}
    </TextContainer>
  );
};

export const freeBadge = (className?: string) => {
  return (
    <TextContainer
      color={"bg-free-background text-dark-brown"}
      className={className}
    >
      {entitlementToString("NONE")}
    </TextContainer>
  );
};

const OptionsCard: React.FC<OptionsCardProps> = ({ entitlement, description, pricesProducts, user, setSelectedProduct }) => {
  var featureList = getFeatures(entitlement);
  let isCurrentPlan = user?.entitlement === entitlement;
  // let isCurrentPlan = true;
  var _items = getActiveProductsFromPricesProducts(pricesProducts);
  let product: IStripeProductPrice | undefined = undefined;



  const renderFeatures = () => {
    const features = _(entitlement === "PRO" ? "premium-feature-highlights" : "silver-feature-highlights");
    const featureList = features.split('\n');

    // Return a list in JSX
    return (
      <div className="pt-4 pl-2">
        <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
          {featureList.map((feature, index) => (
            // Each feature becomes a list item
            <li key={index} className="font-light">{feature}</li>
          ))}
        </ul>
      </div>
    );
  }



  for (var item of _items) {
    if (item.product.metadata.duration === "YEAR" && item.product.metadata.entitlement === entitlement) {
      product = item;
    }
  }

  let monthPrice = "$0.00";
  if (product) {
    monthPrice = priceToReadable(product.price.monthPrice, product.price.currency);
  }

  let plusText = "Includes:";
  if (entitlement === "BROWSING") {
    plusText = "Free plan, plus:";
  } else if (entitlement === "PRO") {
    plusText = "Silver plan, plus:";
  }

  const getBadge = () => {
    const className = "font-normal text-lg px-2 rounded-md";
    if (entitlement === "PRO") {
      return premiumBadge(className);
    }
    if (entitlement === "BROWSING") {
      return silverBadge(className);
    }
    return freeBadge(className);
  };

  return (
    <div className={`bg-white px-2 py-3 rounded-lg shadow-md w-full transition duration-200 ease-in-out transform hover:scale-105 hover:shadow-xl cursor-pointer border border-${entitlement === "PRO" ? "premium-background" : "browser-background"}`}
      onClick={() => {
        trackEvent("select_subscription", { entitlement: entitlement });
        setSelectedProduct(product);
      }}
    >


      <div className="flex items-center">
        {getBadge()}
        {entitlement === "PRO" ? (
          <div className="ml-2">
            {_(plusText)}
          </div>
        ) : null}
      </div>

      {renderFeatures()}
      <>

        <div className={`w-full mt-3 shadow-md font-medium py-2 px-2 rounded-lg text-sm pt-2 pb-2 text-center ${isCurrentPlan ? "bg-white text-primary-brown border border-primary-brown" : "bg-primary-brown text-white"}`}>
          {isCurrentPlan ?
            <span className={clsx(isCurrentPlan && "text-primary-brown")}>
              {_("Current plan")}
            </span>
            :
            <div className={clsx("font-light", entitlement === "NONE" ? "text-primary-brown opacity-20" : "")}>
              {_("price-from")} <span className="font-semibold">{monthPrice}</span> / {_("price-month")}
            </div>
          }
        </div>
        {/* <div className={clsx("font-light text-sm", entitlement === "NONE" && "text-white ")}>
          {entitlement !== "NONE" ? "Includes a 14 day trial period." : "  -"}
        </div> */}
      </>
    </div>
  );
};

export default OptionsCard;
